import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc, date2str } from '../../../../utils/common';

const URL = '/patients';
const initialState = {
    msg: null,
    loading: false
};
const initialPatient = {
    id: null,
    firstname: '',
    lastname: '',
    middlename: '',
    email: '',
    dob: '',
    street: '',
    street2: '',
    postcode: '',
    suburb: '',
    state: '',
    phone: '',
    status: 1
};

export default function PatientDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const [state, setState] = useState(initialState);
    const [patient, setPatient] = useState(initialPatient);
    const [showPopup, setShowPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(0);

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Patients',
                link: '/patients'
            },
            {
                title: 'Patient Detail',
                link: `/patients/${id}`
            }]);
        loadData(id);
    }, [id]);

    const loadData = (id) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${URL}/${id}`).then((result) => {
            console.log("GetFromServer", result.data);
            setPatient({ ...result.data, dob: date2str(result.data.dob) });
            setState({ ...state, loading: false, msg: null });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setPatient(initialPatient);
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        });
    };

    const handleStatus = (status) => {
        console.log("To change status: ", status);
        setNewStatus(status);
        setShowPopup(true);
    };

    const changeStatus = () => {
        setShowPopup(false);
        if (patient && patient.id) {
            var data = {
                uid: patient.id
            };
            var statusUrl = newStatus === 1 ? "/user/activateuser" : "/user/deactivateuser";

            setState({ ...state, loading: true, msg: null });
            axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
            axios.post(statusUrl, data).then((result) => {
                console.log(statusUrl + ": ", result.data);
                loadData(patient.id);
                setState({ ...state, loading: false, msg: null });
            }).catch((error) => {
                console.log('activateuser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const handleBack = () => {
        setPatient(initialPatient);
        setState({ ...state, changing: false });
        navigate(-1);
    };

    const gotoAppointments = (val) => {
        console.log("gotoAppointments: ", val);
        navigate("/appointments?text=" + val + "");

    };
    const gotoResources = (val) => {
        console.log("gotoResources: ", val);
        navigate("/resources/patient/" + val);

    };

    return (

        <div>
            <div className="card-body">
                <Spinner value={state.loading} />

                <form role="form " >
                    <div className="row">
                        <div className="col">
                            <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">First Name</label>
                                        <input id="repName" type="text" className="form-control" value={patient.firstname} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">Middle Name</label>
                                        <input id="repName" type="text" className="form-control" value={patient.middlename} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group ">
                                        <label htmlFor="repName" className="font-weight-normal">Surname</label>
                                        <input id="repName" type="text" className="form-control" value={patient.surname} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repDOB" className="font-weight-normal">Date Of Birth</label>
                                        <input id="repDOB" type="text" className="form-control" value={patient.dob} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repStreet" className="font-weight-normal">Street</label>
                                        <input id="repStreet" type="text" className="form-control" value={patient.street} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repStreet" className="font-weight-normal">Optional: Unit, Suite, Floor</label>
                                        <input id="repStreet2" type="text" className="form-control" value={patient.street2} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repSuburb" className="font-weight-normal">Suburb</label>
                                        <input id="repSuburb" type="text" className="form-control" value={patient.suburb} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repState" className="font-weight-normal">State</label>
                                        <input id="repState" type="text" className="form-control" value={patient.state} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repSuburb" className="font-weight-normal">Postcode</label>
                                        <input id="repPostcode" type="text" className="form-control" value={patient.postcode} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmail" className="font-weight-normal">Email</label>
                                        <input id="repEmail" type="text" className="form-control" value={patient.email} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmail" className="font-weight-normal">Phone</label>
                                        <input id="repPhone" type="text" className="form-control" value={patient.phone} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repStatus" className="font-weight-normal">Status</label>
                                        <input id="repStatus" type="text" className="form-control" value={patient.status === 1 ? 'ACTIVE' : patient.status === 2 ? 'INACTIVE' : patient.status === 0 ? 'NEW' : 'OTHER'} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '20px' }}>
                        <div className='col text-left'>
                        <Button variant="secondary" style={{ width: '100px', marginRight: '10px' }} onClick={() => handleBack()}>Back</Button> {/* Back button */}
                            {patient && patient.status > 0 ? <Button variant="secondary" style={{ marginRight: '10px' }} onClick={() => gotoAppointments(patient.email)}> Appointments</Button> : <></>}
                            <>  </>
                            {patient && patient.status > 0 ? <Button variant="secondary" style={{ width: '100px' }} onClick={() => gotoResources(id)}> Resources</Button> : <></>}
                            <></>
                        </div>

                        <div className="col text-right">
                            {patient && patient.status !== 2 ? <Button variant="warning" style={{ marginRight: '10px', width: '100px'}} onClick={() => handleStatus(2)}> Deactivate</Button> : <></>} <></>
                            {patient && patient.status !== 1 ? <Button variant='success' style={{ width: '100px' }} onClick={() => handleStatus(1)}> Activate</Button> : <></>}
                        </div>
                    </div>
                    <div className='row'>
                        <PopupPage
                            size="md"
                            openDialog={showPopup}
                            setOpenDialog={setShowPopup}
                            title={patient.status !== 1 ? "Activate patient" : "Deactivate patient"}
                            label='Yes'
                            onSave={changeStatus}
                            labelCancel='No'>
                            <p>Are you sure to change this patient's status?</p>
                        </PopupPage>
                    </div>
                </form>
            </div >
        </div >
    );
}
