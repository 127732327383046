import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import UserContext, { getUser } from '../../../contexts/UserContext';
import ContentContext from '../../../contexts/ContentContext';
import css from './style.module.css';

const initialUserState = {
    id: 0,
    surname: 'SurName',
    firstname: 'FirstName',
    email: 'email@example.au',
    role: 'ADMIN',
    cid: 1,
    initials: 'US',
    fullname: 'User'
}

export default function Header() {
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState(initialUserState);
    const contentCtx = useContext(ContentContext);

    useEffect(() => {
        var u = userCtx.getUser();
        var initials = '';
        var fullname = u.firstname;
        //console.log('UserInfo', u);



        if (u.firstname)
            initials = u.firstname.at(0);
        if (u.surname) {
            initials = initials + u.surname.at(0);
            fullname = fullname + ' ' + u.surname;
        }
        initials = initials.toUpperCase();
        setUser({ ...u, initials, fullname });
    }, [])

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    {/* <Link className="nav-link" data-widget="pushmenu" to='/' role="button"><i className="fas fa-bars" /></Link> */}
                    <Link className="nav-link" data-widget="pushmenu" to={contentCtx.path} role="button"><i className="fas fa-bars" /></Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link className="nav-link" to='/'>Home</Link>
                </li>
                {/* <li className="nav-item d-none d-sm-inline-block">
                    <a href="#" className="nav-link">Contact</a>
                </li> */}
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#">
                        <p>
                            Hi, {user.fullname}
                        </p>
                    </a>
                </li>
                <li className="nav-item dropdown" style={{ marginTop: '-6px' }}>
                    <a className="nav-link" data-toggle="dropdown" href="#" role="button">
                        <div className={css.userAvatar}>
                            <div className={css.initials}>{user.initials}</div>
                        </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu dropdown-menu-right">
                        {/* <a href="#" className="dropdown-item">
                            <i className="fas fa-user mr-2" /> User Profile
                        </a>
                        <div className="dropdown-divider" /> */}
                        <Link to='/change-password' className="dropdown-item">
                            <i className="fas fa-key mr-2" /> Change Password
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to='/signout' className="dropdown-item">
                            <i className="fas fa-times mr-2" aria-hidden="true" /> Sign Out
                        </Link>
                    </div>
                </li>
            </ul>
        </nav>
    )
}
