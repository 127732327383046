import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import MsgBox from '../../../controls/MsgBox';
import { Button, Col, Form, Row } from 'react-bootstrap';
import './signup.css';
import ResultModal from '../../../controls/ResultModal';

const URL = 'login/register-clinic'; // API endpoint for clinic sign-up
const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

const InputField = ({ controlId, label, type, value, onChange, placeholder, feedback,
    required = true, width = "12", isValid = false, isInvalid = false }) => (
    <Form.Group as={Col} md={width} controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
            isValid={isValid}
            isInvalid={isInvalid}
        />
        <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
    </Form.Group>
);

export default function SignUp() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext); // Get user context

    const [formData, setFormData] = useState({
        clinicName: '',
        clinicEmail: '',
        phone: '',
        street: '',
        suburb: '',
        state: '',
        status: '',
        password: '',
        firstname: '',
        lastname: '',
        postcode: ''
    });

    // verification status
    const [formValidation, setFormValidation] = useState({
        clinicName: null,
        clinicEmail: null,
        phone: null,
        street: null,
        suburb: null,
        state: null,
        status: null,
        password: null,
        firstname: null,
        lastname: null,
        postcode: null
    });

    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);
    const [validated, setValidated] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseModal = () => {
        navigate('/');
        setIsModalOpen(false);
    }

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        // Update form data
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));

        // Update verification status
        setFormValidation((prevValidation) => {
            const updatedValidation = { ...prevValidation };
            updatedValidation[id] = validateField(id, value)

            return updatedValidation;
        });
    };


    const handleAxiosError = (error) => {
        const responseMessage = error.response?.data?.message || 'An unexpected error occurred.';
        setError(`Registration failed: ${responseMessage} Please check your details and try again.`);
        setMsg(null);
    };

    const isValidForm = (form) => {
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        return true;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        // if (
        //     !formData.isValidPostcode ||
        //     !formData.isValidPhone ||
        //     !formData.isValidEmail
        // ) {
        //     // setError("Please fix the errors before submitting.");
        //     return;
        // }

        if (!isValidForm(form)) return;

        axios
            .post(URL, formData)
            .then((result) => {
                // setMsg(result.data.message);
                setIsModalOpen(true);
                setError(null);
            })
            .catch(handleAxiosError);
    };

    const cancel = () => navigate('/signin');

    const formItems = {
        clinicName: {
            controlId: "clinicName",
            label: "Name of Medical Institution",
            placeholder: "Enter institution name",
            feedback: "Please provide a valid name.",
            width: "12",
            rule: { required: true, regex: /^[a-zA-Z0-9'\s]{2,50}$/ }
        },
        firstname: {
            controlId: "firstname",
            label: "Your First Name",
            placeholder: "Enter first name",
            feedback: "Please provide a valid first name.",
            width: "6",
            rule: { required: true, regex: /^[a-zA-Z]{2,30}$/ }
        },
        lastname: {
            controlId: "lastname",
            label: "Your Last Name",
            placeholder: "Enter last name",
            feedback: "Please provide a valid last name.",
            width: "6",
            rule: { required: true, regex: /^[a-zA-Z]{2,30}$/ }
        },
        clinicEmail: {
            controlId: "clinicEmail",
            label: "Email",
            placeholder: "Enter email",
            feedback: "Please provide a valid email address.",
            width: "6",
            rule: { required: true, regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }
        },
        phone: {
            controlId: "phone",
            label: "Phone",
            placeholder: "Enter phone number",
            feedback: "Please provide a valid Australian phone number.",
            width: "6",
            rule: { required: true, regex: /^((\+61|0)4\d{8})$|^((\+61|0)[2-9]\d{8})$/ }
        },
        street: {
            controlId: "street",
            label: "Street",
            placeholder: "Enter street",
            feedback: "Please provide a valid street name.",
            width: "5",
            rule: { required: true, regex: /^[a-zA-Z0-9\s,']{2,100}$/ }
        },
        suburb: {
            controlId: "suburb",
            label: "Suburb",
            placeholder: "Enter suburb",
            feedback: "Please provide a valid suburb name.",
            width: "3",
            rule: { required: true, regex: /^[a-zA-Z\s']{2,50}$/ }
        },
        state: {
            controlId: "state",
            label: "State",
            placeholder: "",
            feedback: "Please select a valid state.",
            width: "2",
            rule: { required: true, regex: /^(ACT|NSW|NT|QLD|SA|TAS|VIC|WA)$/ }
        },
        postcode: {
            controlId: "postcode",
            label: "Postcode",
            placeholder: "Enter postcode",
            feedback: "Postcode must be exactly 4 digits.",
            width: "2",
            rule: { required: true, regex: /^[0-9]{4}$/ }
        },
        password: {
            controlId: "password",
            label: "Password",
            placeholder: "Enter password",
            feedback: "Password must be at least 6 characters.",
            width: "12",
            type: "password",
            rule: { required: true, regex: /^.{6,}$/ }
        }
    };


    const validateField = (field, value) => {
        const rule = formItems[field].rule;

        if (!rule) {
            return true;
        }

        if (rule.required && !value) {
            return false;
        }

        if (rule.regex && !rule.regex.test(value)) {
            return false;
        }

        return true;
    }

    const formRows = [
        ["clinicName"], // 1st row
        ["firstname", "lastname"],  // 2nd row
        ["clinicEmail", "phone"], // 3rd row
        ["street", "suburb", "state", "postcode"], // 4th row, including state dropdown
        ["password"], // 5th row
    ];

    return (
        <div className="d-flex flex-row-fluid login-page position-relative p-7 overflow-hidden col-lg-8 signup-card-width">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <div className="hold-transition">
                    <div
                        className="login-box signup-card"
                        style={{ overflowY: 'auto', maxHeight: '100vh', width: '150%', marginLeft: '-50px' }}
                    >
                        <div className="card card-outline card-primary">
                            <div className="card-header text-center">
                                <h2>Sign Up</h2>
                            </div>
                            <div className="card-body">
                                {error && <MsgBox value={{ type: 'error', title: '', msg: error }} />}
                                {/* {msg && <MsgBox value={{ type: 'success', title: '', msg }} />} */}
                                {!msg && (
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        {formRows.map((rowFields, rowIndex) => (
                                            <Row key={rowIndex} className="mb-3">
                                                {rowFields.map((field) => {
                                                    if (field === "state") {
                                                        return (
                                                            <Form.Group as={Col} md="2" controlId="state" key="state">
                                                                <Form.Label>State</Form.Label>
                                                                <Form.Select
                                                                    id="state"
                                                                    value={formData.state}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                    isValid={formValidation.state === true}
                                                                    isInvalid={formValidation.state === false}
                                                                >
                                                                    <option value="" disabled>Select</option>
                                                                    {STATES.map((state) => (
                                                                        <option key={state} value={state}>
                                                                            {state}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please select a valid state.
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="valid">
                                                                    Looks good!
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        );
                                                    }

                                                    const config = formItems[field];
                                                    return (
                                                        <InputField
                                                            key={field}
                                                            controlId={config.controlId}
                                                            label={config.label}
                                                            type={formItems[field].type || "text"}
                                                            placeholder={config.placeholder}
                                                            value={formData[field]}
                                                            onChange={handleInputChange}
                                                            feedback={config.feedback}
                                                            isValid={formValidation[field] === true}
                                                            isInvalid={formValidation[field] === false}
                                                            width={config.width}
                                                        />
                                                    );
                                                })}
                                            </Row>
                                        ))}

                                        <div className="d-flex justify-content-end">
                                            <Button variant="secondary" onClick={cancel}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" type="submit" className="ml-2">
                                                Sign Up
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ResultModal isModalOpen={isModalOpen} closeModal={handleCloseModal} />
        </div>
    );
}
