import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import DataTable from '../../../controls/DataTable';
import DataTablePagination from '../../../controls/DataTablePagination';
import SearchBox from '../../../controls/SearchBox';
import MsgBox from '../../../controls/MsgBox';
// import Spinner from '../../../controls/Spinner';
import { getErrorDesc } from '../../../../utils/common';
import ActivationModal from '../../../controls/ActivationModel';
import LoadingModal from '../../../controls/LoadingModal';
import SuccessModal from '../../../controls/SuccessModal';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const API_URL = "clinics";
const URL = "/clinics";

const titles = [
    "ID",
    "Name",
    "Address",
    "Contact",
    "Phone",
    "Email",
    "Created",
    "Status"
];

const cols = [
    "cid",
    "name",
    "address",
    "contact_person",
    "phone",
    "email",
    "create_date",
    "status"
];


const statusList = ["", "NEW", "ACTIVE", "INACTIVE"]

const initState = {
    data: [],
    selectedRow: null,
    loading: false,
    msg: null
}

const initPaginationState = {
    total: 0,
    page: 1,
    size: 10
}

const initFilterState = {
    text: '',
    selectedStatus: statusList[0]
}

const ClinicList = ({ }) => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);
    const [state, setState] = useState(initState);
    const [currentPage, setCurrentPage] = useState(initPaginationState);
    const [filter, setFilter] = useState(initFilterState);

    // New state for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClinic, setSelectedClinic] = useState(null); // Store clinic info being updated
    const [successModelOpen, setSuccessModelOpen] = useState(false);

    useEffect(() => {
        contentCtx.updatePath([{ title: 'Clinics', link: '/clinics' }]);
        loadData(filter);
    }, [currentPage.page, currentPage.size, filter.selectedStatus, filter.text]);

    const loadData = (filterValues) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(API_URL,
            {
                params: {
                    page: currentPage.page,
                    size: currentPage.size,
                    status: filterValues.selectedStatus,
                    text: filterValues.text
                },
            }).then((result) => {
                // console.log("API Data:", result.data); // debug
                // console.log("Filter Params:", {
                //     status: filter.selectedStatus,
                //     text: filter.text
                // });      
                // console.log("GetFromServer Clinics", result.data);
                setState({
                    ...state, data: formatData(result.data.data), loading: false, msg: null
                });
                // console.log("Formatted Data:", formatData(result.data.data));
                setCurrentPage({ ...currentPage, total: result.data.total });
            }).catch((error) => {
                console.log('error:', error);
                if (error.response && error.response.status === 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state, data: [], loading: false, msg: getErrorDesc(error)
                    });
                }
            })
    }

    const searchText = (text) => {
        console.log('searchText', text);
        setFilter({ ...filter, text });
        setCurrentPage({ ...currentPage, page: 1 }); // Reset to the first page
    }

    const changeCurrentPage = (page) => {
        setCurrentPage({ ...currentPage, page });
    }

    const changePageSize = (size) => {
        setCurrentPage({ ...currentPage, size });
    }

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        return new Intl.DateTimeFormat('en-AU', { timeZone: 'UTC', day: '2-digit', month: 'short', year: 'numeric' }).format(date);
    };

    const formatData = (data) => {
        // if (!Array.isArray(data)) {
        //     console.warn("Data is not an array:", data);
        //     console.log(data)
        //     return []; // Return an empty array if data is invalid
        // }
        return data.map((clinic) => {
            return {
                ...clinic,
                address: `${clinic.street}, ${clinic.suburb}, ${clinic.state}, ${clinic.postcode}`,
                create_date: formatDate(clinic.create_date),
                contact_person: (clinic.firstname !== null && clinic.surname !== null) ? `${clinic.firstname} ${clinic.surname}` : "N/A"
            }
        });
    };

    // Function to open modal and set selected clinic
    const handleToggleClick = (clinic) => {
        setSelectedClinic(clinic); // Save the clinic data
        setIsModalOpen(true); // Show the modal
    };

    // Function to confirm the toggle action
    const confirmToggle = () => {
        if (!selectedClinic) return;

        setState({ ...state, loading: true });

        const newStatus = selectedClinic.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

        axios
            .patch(
                `${API_URL}/${selectedClinic.cid}/status`,
                { status: newStatus },
                { headers: { Authorization: `Bearer ${userCtx.getToken()}` } }
            )
            .then(() => {
                setIsModalOpen(false); // Close modal
                loadData(filter); // Reload data
                setSuccessModelOpen(true);
            })
            .catch((error) => {
                console.error("Error updating status:", error);
                setIsModalOpen(false); // Close modal
                setSelectedClinic(null); // Reset selected clinic

                // Update the status message and end the load
                setState({
                    ...state,
                    loading: false
                });
            });
    };

    // Function to cancel the toggle action
    const cancelToggle = () => {
        setIsModalOpen(false); // Close modal
        // setSelectedClinic(null); // Reset selected clinic
    };

    const handleFilterStatus = (selectedStatus) => {
        // const value = event.target.value;
        if (process.env.NODE_ENV === 'development') {
            console.log('Filter updated:', selectedStatus); // Debug: check trigger updating value
        }
        setFilter({
            ...filter,
            // selectedStatus: value
            selectedStatus
        });
        setCurrentPage({ ...currentPage, page: 1 }); // Reset to the first page
    };

    // Function to cancel the toggle action
    const cancelSuccessModel = useCallback(() => {
        setSuccessModelOpen(false); // Close modal
        setSelectedClinic(null); // Reset selected clinic
    }, []);

    return (
        <>
            <div className="row mb-4">
                <div className="col text-left">
                    <SearchBox findText={searchText} />
                </div>
                <div className="col text-right">
                    <DropdownButton variant="primary" title="Status" onSelect={handleFilterStatus}>
                        <Dropdown.Item eventKey={statusList[0]} active={filter.selectedStatus === statusList[0]}>All</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey={statusList[2]} active={filter.selectedStatus === statusList[2]}>Active</Dropdown.Item>
                        <Dropdown.Item eventKey={statusList[1]} active={filter.selectedStatus === statusList[1]}>New</Dropdown.Item>
                        <Dropdown.Item eventKey={statusList[3]} active={filter.selectedStatus === statusList[3]}>Inactive</Dropdown.Item>
                    </DropdownButton>

                    {/* Filter Dropdown */}
                    {/* <label>Filter by Status:
                        <select name="statusFilter" value={filter.selectedStatus} onChange={handleFilterStatus}>
                            <option value="">All</option>
                            <option value={statusList[2]}>Active</option>
                            <option value={statusList[1]}>New</option>
                            <option value={statusList[3]}>Inactive</option>
                        </select>
                    </label> */}
                </div>
            </div>

            <div className="row mb-6 p-10" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="col-12" style={{ flex: '1', overflow: 'hidden' }}>
                    {/* Table container */}
                    <div style={{ position: 'relative', width: '100%', overflowX: 'auto' }}>
                        <DataTable
                            module={URL}
                            id='cid'
                            list={{
                                data: state.data,
                                columns: cols,
                                labels: titles
                            }}
                            view3={{
                                label: "CONTROL",
                                colSize: 1,
                                buttonSize: 12,
                                buttons: [
                                    {
                                        label: '',
                                        func: handleToggleClick
                                    }]
                            }}
                            onToggle={handleToggleClick} // Pass toggle function
                        />
                    </div>
                </div>
                {/* Paginator, fixed below the table and sliding with the scroll bar */}
                <div style={{ width: '100%', paddingTop: '10px', textAlign: 'center', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    <DataTablePagination
                        page={currentPage.page}
                        size={currentPage.size}
                        total={currentPage.total}
                        setPage={changeCurrentPage}
                        setSize={changePageSize}
                    />
                </div>
                <ActivationModal isModalOpen={isModalOpen} selectedClinic={selectedClinic} cancelToggle={cancelToggle} confirmToggle={confirmToggle} />
                <LoadingModal show={state.loading && !isModalOpen && filter.text === ''} />
                <SuccessModal isModalOpen={successModelOpen} selectedClinic={selectedClinic} closeModel={cancelSuccessModel} />
                {state.msg !== null && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}
            </div>
        </>
    )
}

export default ClinicList; 